.container__consultingPost {
    width: 90%;
    margin: auto;
    padding-bottom: 80px;
    @media screen and (min-width: 768px) {
        width: 80%;
    }
    @media screen and (min-width: 1024px) {
        width: 70%;
    }
    @media screen and (min-width: 1200px) {
        width: 60%;
    }
}

p {
    font-size: 18px;
    line-height: 160%;
    font-weight: 400;
    margin-bottom: 15px;
}

.image img {
    width: 70%;
}

blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
    border-left: 5px solid #eee;
    background: #eee;
}

blockquote p {
    padding: 20px;
    border-radius: 5px;
}

.loading-container {
    height: 35rem;
    align-items: center;
}
.consulting__post .consulting__post__backButton a
{
    text-decoration: none;
    color: #000;
    font-size: 16px;
    justify-content: flex-start;
    display: flex;
    align-items: center;
}
.consulting__post
{
    width: 100%;
}
.consulting_post_title
{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 36px;
    color: #000;
    text-align: center;
    margin-top: 20px;
    @media screen and (min-width: 768px) {
        font-size: 28px;
    }
    @media screen and (min-width: 1024px) {
        font-size: 32px;
    }
}
