@keyframes animatetop {
    from {top: -300px; opacity: 0}
    to {top: 0; opacity: 1}
}
@keyframes fadeDown {
    0% {
        opacity: 1;
        transform: translateY(0);
      }
      100% {
        opacity: 0;
        transform: translateY(-100%);
        display: none;
      }
  }
.modal__category
{
    position: fixed;
    z-index: 1000;
    top:0;
    left:0;
    width: 100%;
    right: 0;
    bottom:0;
    background-color: rgba(0,0,0,.8);
    animation-duration: .8s;
    animation-name: animatetop;
}
.modal__category__d-none
{
    position: fixed;
    z-index:0;
    top:0;
    left:0;
    width: 100%;
    right: 0;
    bottom:0;
    background-color: rgba(0,0,0,.8);
    animation: fadeDown 1s forwards;
}
.consulting__category__list
{
    list-style: none;
    padding-left: 0;
    position: relative;
}
.consulting__category__list::before
{
    content: "";
    width: 100%;
    height: 20%;
    max-height: 200px;
    position: absolute;
    bottom: -10px;
    left: 0;
    background: linear-gradient(180deg,hsla(0,0%,100%,0),#fff);
    pointer-events: none;
    z-index: 1;
}
.consulting__category__item:hover
{
    text-decoration: underline;
}
.modal__category .consulting__category
{
    position: relative;
    width: 100%;
    background-color: #fff;
    padding:40px 0 80px 0;
    height: 80vh;
    text-align: center;
}
.post-card {
    min-height: 300px;
    padding-top: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1;
}
.post-card:hover
{
    transform: scale(1.08);
    z-index: 2;
}

.tag {
    display: flex;
    font-size: 18px;
    color: #CA3C08;
}

.tag p {
    margin-right: 1em;
}

.btn {
    font-size: 15px !important;
    border-radius: 5px;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    background-color: #0168F8;
    height: 40px;
}
.post-image img{
    height: 200px;
}
.loading-container {
    height: 35rem;
    align-items: center;
}
h3.title__custom
{
    font-size: 22px;
    min-height: 50px;
    color:black;
}
p.subtitle__custom
{
    font-size:14px;
}