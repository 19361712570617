.jumbotron {
  text-align: center;
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.container {
  width: 20%
}

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#root {
  background: #f8f9fc;
}

.App-link {
  color: #61dafb;
}

.bg-gray {
  height: 100%;
  background: #f8f9fc;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.center_div
{
  position: absolute;
  top:50%;
  width: 400px;
  left: 50%;
  transform: translate(-50%,-50%);
  @media screen and (max-width: 400px) {
    width: 90%;    
  }
  
}