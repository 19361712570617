.introduction
{
    max-width: 100vw;
    /* background-color: #0A1E4D;     */
}

@media screen and (max-width: 768px)
{
    .introduction .col-12
    {
        padding:0;
    }         
}
.introduction .break__line
{
    border: 1px solid #ffffff;
    width: 50px;
    margin: 0;
}
.introduction .introduction__content .introduction__subtitle
{
    color: #4942E4;
    font-weight: 700;
}
.introduction .introduction__content .introduction__title
{
    text-transform: uppercase;
    @media screen and (max-width: 768px)
    {
        font-size: 24px;
    }

}
.introduction .introduction__content .introduction__title, .introduction .introduction__content .introduction__description
{
    color:white;
}
.introduction_description p.display_content{
    font-size: 16px;
    line-height: 22px;
    padding-left: 0;
}
.introduction .introduction__content .introduction__content_detail .introduction__subtitle
{
    text-transform: uppercase;
    @media screen and (max-width: 768px)
    {
        font-size: 18px;    
    }
}
.introduction .introduction__content .introduction__content_detail .introduction__description
{
    @media screen and (max-width: 768px)
    {
        font-size:16px;
    }
}
.introduction .introduction__content .introduction__title
{
    padding-bottom: 2px;
    border-bottom: 2px solid #fff;
    @media screen and (min-width: 768px) {
        font-size: 24px;
        line-height: 140%;
    }
    @media screen and (min-width: 1024px) {
        font-size: 30px;
        line-height: 140%;
        font-weight: 700;
      };
}
.introduction .introduction__content .introduction__title.black
{
    border-bottom: 2px solid #000;
}
.introduction button.rn-button-style--2, .introduction a.rn-button-style--2 
{
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: 2px solid #b1b4c1;
    padding: 15px 40px;
    border-radius: 6px;
    display: inline-block;
    font-weight: 500;
    transition: .3s;       
    @media screen and (min-width: 768px) {
        margin-left:16px;
    }
}
.introduction a.rn-button-style--2.black
{
    color: white;
    background-color: #0164f8;
}
.introduction a.rn-button-style--2.black:hover
{
    background-color: transparent;
    border-color: #000;
}
.introduction a.rn-button-style--2:hover
{
    background: #ffffff;
    border-color: #ffffff;
    color: #0164f8;
    text-decoration: none;
}
.image
{
    width:100%;
    margin:auto;
    @media screen and (min-width: 768px)
    {
        width:80%;
    }
    @media screen and (min-width: 1024px)
    {
        width:100%;
    }
}
.display_content
{
    text-align: justify;
}
@media screen and (min-width:1440px)
{
    .introduction_description
    {
        /* height: 280px; */
        /* padding-left: 20px !important; */
        padding-left:15px !important;
    }   
    .introduction__content_detail li
    {
        font-size: 16px;
    }
}
@media screen and (max-width:1208px) 
{
    .display_content
    {
        display:-webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0px;
        text-align: left;
    }  
    .introduction_description
    {
        height: 300px;
        padding-left: 20px !important;
    }
}
@media screen and (min-width:992px) 
{
    .introduction_description
    {
        max-height: 350px;
        min-height: 150px;
        height:fit-content;
        padding-right: 16px !important;
        text-align: justify;
        overflow-y: auto;
        overflow-x: hidden;
        padding-left: 15px !important;
        margin-bottom: 20px;
    }
    .introduction_description p.display_content
    {
        font-size:18px;
        text-align: justify;
        line-height: 1.5;
    } 
    .introduction__content_detail li
    {
        font-size: 18px;
    }
}
@media screen and (max-width:991px) 
{
    .introduction_description
    {
        max-height: 400px;
        height:fit-content;
        padding-right: 16px !important;
        text-align: justify;
        overflow-y: auto;
        overflow-x: hidden;
        padding-left: 0px !important;
        margin-bottom: 20px;
    }
    .introduction_description p.display_content
    {
        font-size:16px;
        text-align: justify;
    } 
    .introduction__content_detail li
    {
        font-size: 16px;
    }
    .introduction__image img
    {
        width: 50% !important;
        margin-bottom: 40px;
    }
}
@media screen and (max-width:600px)
{
    .introduction__image
    {
        height: 300px;
    }
    .display_content
    {
        display:-webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0px;
        text-align: left;
    }  
    .introduction__image
    {
        display:block;
    }    
    .display_content_ul
    {
        padding-left:10px;
    }  
    .introduction_description
    {
        max-height: 400px;
        height:fit-content;
        padding-right: 16px !important;
        text-align: justify;
        overflow-y: auto;
        overflow-x: hidden;
        padding-left: 10px !important;
        margin-bottom: 20px;
    }
    .introduction_description p.display_content
    {
        font-size:14px;
        text-align: justify;
    }
    .introduction__title
    {
        padding-left:10px;
    }
    .introduction__content_detail li
    {
        font-size: 14px;
    }
}
@media screen and (max-width:400px)
{
    .display_content_ul
    {
        display:none;
        /* padding-left:10px; */
    }  
}
.introduction_width
{
    width: 100%;
}
@media screen and (min-width: 1208px)
{
    .introduction_width
    {
        width: 75% !important;
    }
}
.introduction__image img
{
    border-radius: 10px;
}
.pack_post
{
    margin-bottom: 20px;
    max-height: 380px;
    overflow-y: auto;
}
#style-4::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

#style-4::-webkit-scrollbar-thumb
{
	background-color: #000000;
	border: 2px solid #555555;
    border-radius: 5px;
}
.line_post
{
    min-height: 320px;
    @media screen and (min-width: 500px)
    {
        min-height: 250px;
    }
    @media screen and (min-width: 768px)
    {
        min-height: 200px;
    }
}
#heading
{
    font-size: 24px;
    line-height: 36px;
    @media screen and (min-width: 600px)
    {
        font-size: 32px;
        line-height: 48px;
    }
}
span.tags
{
    font-size: 18px;
    line-height: 24px;
    @media screen and (min-width: 600px)
    {
        font-size: 18px;
        line-height: 27px;
    }
}