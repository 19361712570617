@media screen and (max-width: 576px) {
    .forum-cate {
        height: 50px;
        overflow: hidden;
        margin-bottom: 20px;
    }
}

.loading-container {
    height: 35rem;
    align-items: center;
}
.summary_desc
{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    @media screen and (min-width: 768px) {
        -webkit-line-clamp: 1;
    }
}
.absolute_top_right
{
    position: absolute;
    top: 0;
    right: 0;
}