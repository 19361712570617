nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
}
nav.header
{
    z-index:999;
    position:sticky;
    top:0;
    left:0;
    right:0;
    margin:0;
}
#navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    /* padding-top: 1rem; */
}

#navbar li {
    list-style: none;
    position: relative;
    margin-bottom: 8px;
    @media screen and (max-width: 1320px) {
        margin-bottom: 0;
    }
}

#navbar li a {
    text-decoration: none;
    font-size: 1rem;
    font-weight: 700;
    color: #000;
    transition: 0.3s ease-in-out;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 1.2rem;
    @media screen and (max-width: 1320px) {
        padding-top: 0;
    }
    @media screen and (max-width: 1440px) {
        font-size:14px;
    }
}

.nav-item {
    height: 100%;
    margin-left: 1.3rem;
}
@keyframes run {
    0% {
      width:  0;
    }
    100%
    {
      width: 100%;
    }
  }

#navbar li a.active::after {
    -webkit-animation:1;
    -webkit-animation-name: run;
    -webkit-animation-duration: 1s;
    position: absolute;
    left: 0;
    bottom: 4px;
    right: 0;
    height: 4px;
    width: 100%;
    background: #0164f8;
    content: "";
    margin: 0 auto;
}

#navbar li>form.mainmenu__form {
    display: flex;
    margin-top: 12px;
}

@media (min-width:1440px) {
    #navbar li>form.mainmenu__form {
        margin-left: 1.8rem;
    }
}



.login-btn {
    align-items: center;
    display: flex;
    margin-right: 1rem;
    list-style: none;
}

#mobile {
    display: none;
}

#mobile i {
    color: #000;
    align-items: center;
}

.close-btn {
    display: none;
}

.login-btn-responsive {
    margin-top: 1rem;
}

@media screen and (min-width: 1200px) {
    .login-btn-responsive {
        display: none;
    }
}

@media screen and (max-width: 1325px) {
    #navbar {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 0;
        right: -300px;
        width: 300px;
        height: 100vh;
        background: #fff;
        box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
        padding: 30px 20px 0 20px;
        transition: 0.3s ease-in-out;
        z-index: 99;
    }

    #navbar.active {
        right: 0px;
        display: table;
    }

    #navbar li a.active::after {
        bottom: -8px;
    }

    #navbar li {
        width: fit-content;
        margin-bottom: 16px;
        height: fit-content;
    }

    .login-btn-responsive {
        display: table-header-group;    
    }

    .login-btn-responsive a {
        margin-left: 20px;
    }

    #mobile {
        display: block;
        margin-right: 2rem;
    }

    #navbar li a {
        padding-top: 0;
    }

    #mobile i {
        font-size: 24px;
        cursor: pointer;
    }

    .nav-item {
        margin-right: 40px;
    }

    .close-btn.nav-item {
        display: flex;
        justify-content: end;
        width: 100%;
    }

    .login-btn {
        display: none;
    }
}
@media screen and (max-width: 620px) {
    #navbar {
        width: 216px;
    }
}
.logo__navbar__desktop
{
    display: none;
    @media  screen and (min-width: 1325px) {
        display: block;
    }
}
.logo__navbar__mobile
{
    display: block;
    @media  screen and (min-width: 1325px) {
        display: none;
    }
}