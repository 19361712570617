@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,600,700,800,900|Poppins:300,400,500,600,700,700i&display=swap');
ul.social-share.rn-lg-size li a {
  width: 50px;
  height: 50px;
  line-height: 44px;
  font-size: 16px;
  margin:0 8px;
}
ul.social-share
{
  list-style-type: none;
}
ul.social-share li:hover a {
  background: #0164f8;
  border-color: #0164f8;
  color: #fff;
  box-shadow: 0 10px 15px 0 rgba(0,0,0,.1);
  transform: translateY(-3px);
}
ul.social-share li a {
  width: 40px;
  display: inline-block;
  height: 40px;
  border: 2px solid rgba(198,201,216,.75);
  line-height: 35px;
  color: rgba(198,201,216,.75);
  border-radius: 100%;
  text-align: center;
  font-size: 14px;
}
a:hover
{
  text-decoration: none !important;
}
/*--------------------
 Footer Styles 
 ----------------------*/
 .footer-wrapper {
    position: relative;
  }
  .footer-left {
    /* background: linear-gradient(145deg, #f81f01 0%, #ee076e 100%); */
    padding: 30px 120px;
    position: relative;
    z-index: 2;
    border-top-right-radius: 6px;
  }
  .footer-left .inner>span
  {
    color: #fff;
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 4px;
    display: block;
    margin-bottom: 9px;
  }
  .footer-left .inner>h2
  {
    color: #fff;
    text-align: center;
    font-size: 75px;
    font-weight: 600;
    font-family: "Montserrat",sans-serif;
    line-height: 90px;
    text-transform: uppercase;
  }
  
  @media (min-width: 992px) and (max-width: 1199px) {
    .footer-left {
      padding: 120px 80px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .footer-left {
      padding: 60px 70px;
    }
  }
  @media (max-width: 767px) {
    .footer-left {
      padding: 60px 30px;
    }
  }
  @media (max-width: 567px) {
    .footer-left {
      padding: 60px 5px;
      padding-bottom: 10px;
    }
  }
  .footer-left::before {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    /* background-image: url("./pattern-1.png"); */
    content: "";
    z-index: 1;
    opacity: 0.5;
    border-top-right-radius: 6px;
  }
  .footer-left .inner {
    z-index: 5;
    position: relative;
  }
  .footer-left .inner::after {
    /* content: url("./big-logo.png"); */
    position: absolute;
    right: 0;
    top: 50%;
    z-index: -1;
    font-size: 240px;
    font-weight: 900;
    color: rgba(0, 0, 0, 0.1);
    transform: translateY(-50%);
    width: 295px;
    height: 301px;
  }
  
  @media (min-width: 992px) and (max-width: 1199px) {
    .footer-left .inner h2 {
      font-size: 50px;
      line-height: 61px;
    }
  }
  @media (max-width: 767px) 
  {
    .footer-left .inner h2 {
      font-size: 36px;
      line-height: 51px;
    }
  }
  @media (max-width: 575px) {
    .footer-left .inner > span {
      letter-spacing: 1px;
      margin-bottom: 9px;
    }
    .footer-left .inner h2 {
      font-size: 28px;
      line-height: 51px;
    }
  }
  .footer-left .inner a.rn-button-style--2 {
    margin-top: 30px;
    border-color: #ffffff;
    color: #ffffff;
  }
  @media (max-width: 767px) {
    .footer-left .inner a.rn-button-style--2 {
      margin-top: 10px;
      padding: 10px 23px;
    }
  }
  @media (max-width: 575px) {
    .footer-left .inner a.rn-button-style--2 {
      padding: 7px 16px;
    }
  }
  .footer-left .inner a.rn-button-style--2:hover {
    background: #fff;
    border-color: #fff;
    color: #071aee;
  }
  .footer-right {
    background-image: url("./bg-image-8.jpg");
    padding: 150px 70px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding-left: 120px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .footer-right {
      padding: 119px 21px;
      padding-left: 70px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .footer-right {
      padding: 60px 70px;
    }
  }
  @media (max-width: 767px) {
    .footer-right {
      padding: 60px 30px;
    }
  }
  .footer-right .footer-link h4 {
    color: #c6c9d8;
    font-weight: 500;
    margin-bottom: 30px;
    opacity: 0.75;
  }
  @media (max-width: 767px) {
    .footer-right .footer-link h4 {
      margin-bottom: 14px;
    }
  }
  .footer-right .footer-link ul.ft-link {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .footer-right .footer-link ul.ft-link li a {
    color: #c6c9d8;
    font-size: 16px;
    opacity: 0.75;
  }
  .footer-right .footer-link ul.ft-link li:hover a {
    color: #071aee;
  }
  .footer-right .footer-link ul.ft-link li + li {
    margin-top: 13px;
  }
  @media (max-width: 767px) {
    .footer-right .footer-link ul.ft-link li + li {
      margin-top: 8px;
    }
  }
  /* Footer Style Two  */
 .liststyle
  {
    list-style: none;
    padding-left: 0;
  }
  
  .footer-style-2 {
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('/public/assets/img/logo/footer.svg');
    background-blend-mode: lighten;
    background-color: black;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    font-family: "Poppins",sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
 }
  .footer-style-2 p {
    color: #c6c9d8;
    font-size: 14px;
    opacity: 1;
 }
  .footer-style-2 .text {
    color: white;
 }
  .footer-style-2 .text h4 {
    color: white;
    text-transform: uppercase;
    font-size: 25px;
 }
  .footer-style-2 .text ul > li {
    font-size: 20px;
 }
  .footer-style-2 .text ul > li > a {
    color: white;
    font-size: 20px;
    font-weight: 300;
    margin: 4px 0;
    font-weight: 500;
 }
 .spacing2
  {
    padding: 0 50px;
  }
  .mt
  {
    margin-top: -50px;
  }
 @media only screen and (max-width: 575px) {
  .spacing {
    margin-bottom: 0px;
 }
 .spacing2
 {
  padding: 0 20px;
 }
 @media only screen and (min-width: 1020px) {
  .mt
  {
    margin-top: -20px;
  }
}

}
.logo.text-center .logo__footer
{
    height: 25%;
    width: 25%;
    margin-top:-25px;
    @media only screen and (max-width: 575px)
    {
        height: 50%;
        width: 50%;
        margin-top:0;
        margin-bottom:10px;
    }
}
ul.social-share li {
  margin: 6px;
}